import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Select,
  MenuItem,
  Button,
} from "@material-ui/core";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import TabPanel from "../tab-panel";

import "react-datepicker/dist/react-datepicker.css";
import Navigation from "../navigation";
import "./academic-details.scss";
import { useStateMachine } from "little-state-machine";
import updateAction from "../update-action";

const defaultValues = {

};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}));

function FeesManagement(props) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({ defaultValues });

  const onSubmit = (data) => {
    console.log(JSON.stringify(data));
    actions.updateAction(data);
    console.log('on submit');
    props.history.push("./credit-reference");
  };

  const classes = useStyles();
  const [value, setValue] = useState(0);
  const { actions, state } = useStateMachine({ updateAction });
  const [bursayPay, setBursayPay] = useState(0);
  const [checked, setChecked] = useState(false);
 
  const handleBursaryPay = (event) => {
    setBursayPay(event.target.value);
    event.target.value == "1"
      ? setChecked(true)
      : setChecked(false);
  };

  return (
    <>
      <Navigation />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className={classes.root}>
          <AppBar position="static" color="default"></AppBar>
          <TabPanel value={value} index={0}>
            <Form.Row>
              <Container>
                <Row className="academic-details-wrapper">
                  <Col xs={12} md={3}>
                  
                    <section className="section">
                    <section className="app-year-wrap">
                      
                      <div className="form-group">
                        <label htmlFor="fees_parent_responsible_payment">Who is responsible for the payment of your residence fees?</label>
                        <select className="form-control" name="fees_parent_responsible_payment" id="fees_parent_responsible_payment"
                          {...register("fees_parent_responsible_payment", {
                            required: true,
                          })}
                          // deafultvalue={state.fees_parent_responsible_payment}
                          onClick={(e) => {
                            handleBursaryPay(e);
                          }}
                        >
                          <option value="0">PART BURSARY / PART PARENT OR STUDENT</option>
                          <option value="1">BURSARY COVERS FULL PAYMENT</option>
                          <option value="2">PARENT / STUDENT PAYING FULLY</option>
                        </select>
                      </div>
                      {errors.fees_parent_responsible_payment && (
                        <p className="error">
                          Please select payment responsibility
                        </p>
                      )}
                    </section>
                    <section className="section">
                      <section className="same-address-check">
                      <input
                        className={checked ? 'bursaryChecked': ''}
                        defaultChecked={checked}
                        type="checkbox"
                        id="2111"
                        name="fees_bursary_covers_payment"
                        {...register("fees_bursary_covers_payment", {
                          required: false,
                        })}
                        //onChange={() => setChecked(!checked)}
                      />
                        
                      </section>
                  
                    </section>
                      <section>
                        <label htmlFor="fees_title">
                          Contact details of person/organization responsible for
                          payment
                        </label>
                      </section>
                      <section className="room-preference-wrap">
                        <div className="form-group">
                        <label htmlFor="sel1">Title</label>
                        <select className="form-control" name="fees_title" id="fees_title" 
                         {...register("fees_title", {
                          required: true,
                          })}
                          deafultvalue={state.fees_title}
                          >
                          <option value="0">Mr</option>
                          <option value="1">Ms</option>
                          <option value="2">Mrs</option>
                        </select>
                      </div>
                        {errors.fees_title && (
                          <p className="error">Your title is required</p>
                        )}
                      </section>
                      <section>
                        <label htmlFor="fees_surname">Surname</label>
                        <input
                          {...register("fees_surname", {
                            required: true,
                          })}
                        />
                        {errors.fees_surname && <p className="error">Your Surname is required!</p>}
                      </section>
                      <section>
                        <label htmlFor="fees_first_name">First Name</label>
                        <input
                          {...register("fees_first_name", {
                            required: true,
                          })}
                        />
                        {errors.fees_first_name && (
                          <p className="error">Your First Name is required!</p>
                        )}
                      </section>{" "}
                    </section>
                  </Col>
                  <Col xs={12} md={3}>
                    <section className="section">
                      <section>
                        <label htmlFor="fees_burser_name">Bursar Name</label>
                        <input
                          {...register("fees_burser_name", {
                            required: true,
                          })}
                        />
                        {errors.fees_burser_name && (
                          <p className="error">Your Bursar Name is required!</p>
                        )}
                      </section>
                      <section>
                        <label htmlFor="fees_telephone">Bursar Landline</label>
                        <input
                          {...register("fees_telephone", {
                            required: true,
                          })}
                        />
                        {errors.fees_telephone && (
                          <p className="error">Your Bursar Landline is required!</p>
                        )}
                      </section>
                      <section>
                        <label htmlFor="fees_telephone_country_code">
                          Country Code
                        </label>
                        <input
                          type="number"
                          {...register("fees_telephone_country_code", {
                            required: true,
                          })}
                        />
                        {errors.fees_telephone_country_code && (
                          <p className="error">Your Bursar Landline Country Code is required!</p>
                        )}
                      </section>
                      <section>
                        <label htmlFor="fees_telephone_area_code">
                          Area Code
                        </label>
                        <input
                          type="number"
                          {...register("fees_telephone_area_code", {
                            required: true,
                          })}
                        />
                        {errors.fees_telephone_area_code && (
                          <p className="error">Your Bursar Landline Area Code is required!</p>
                        )}
                      </section>
                      <section>
                        <label htmlFor="fees_mobile">Mobile Number</label>
                        <input
                          {...register("fees_mobile", {
                            required: true,
                          })}
                        />
                        {errors.fees_mobile && (
                          <p className="error">Your Bursar Mobile Number is required!</p>
                        )}
                      </section>
                    </section>
                  </Col>
                  <Col xs={12} md={3}>
                    <section className="section">
                      <section>
                        <label htmlFor="fees_mobile_country_code">
                          Country Code
                        </label>
                        <input
                          type="number"
                          {...register("fees_mobile_country_code", {
                            required: true,
                          })}
                        />
                        {errors.fees_mobile_country_code && (
                          <p className="error">
                            Your Bursar Mobile number Country Code is required!
                          </p>
                        )}
                      </section>
                      <section>
                        <label htmlFor="fees_mobile_area_code">Area Code</label>
                        <input
                          type="number"
                          {...register("fees_mobile_area_code", {
                            required: true,
                          })}
                        />
                        {errors.fees_mobile_area_code && (
                          <p className="error">
                            Your Bursar Mobile number Area Code is required!
                          </p>
                        )}
                      </section>
                      <section>
                        <label htmlFor="fees_fax">Fax Number</label>
                        <input
                          {...register("fees_fax", {
                            required: false,
                          })}
                        />
                        {/* {errors.bursarFaxNumber && (
                          <p>Your Bursar Fax Number is required!</p>
                        )} */}
                      </section>
                      <section>
                        <label htmlFor="fees_fax_country_code">
                          Country Code
                        </label>
                        <input
                          type="number"
                          {...register("fees_fax_country_code", {
                            required: false,
                          })}
                        />
                        {/* {errors.bursarFaxNumberCoutryCode && (
                          <p>
                            Your Bursar Mobile number Country Code is required!
                          </p>
                        )} */}
                      </section>
                      <section>
                        <label htmlFor="fees_fax_area_code">
                          Area Code
                        </label>
                        <input
                          type="number"
                          {...register("fees_fax_area_code", {
                            required: false,
                          })}
                        />
                        {/* {errors.bursarMobileAreaCode && (
                          <p>
                            Your Bursar Mobile number Area Code is required!
                          </p>
                        )} */}
                      </section>
                      <section>
                        <label htmlFor="fees_email">
                          Email Address
                        </label>
                        <input
                          {...register("fees_email", {
                            required: true,
                          })}
                        />
                        {errors.fees_email && (
                          <p className="error">Your Bursar Email Address is required!</p>
                        )}
                      </section>
                    </section>
                  </Col>
                </Row>
              </Container>
            </Form.Row>
            <Row className="academic-btn-wrapper">
            <Col>
                <Button className="submit-btn" type="submit">Next</Button>
              </Col>
            </Row>
          </TabPanel>
        </div>
      </Form>
    </>
  );
}

export default FeesManagement;
