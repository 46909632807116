import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { useStateMachine } from "little-state-machine";
import { Checkbox, Select, MenuItem, TextField } from "@material-ui/core";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import DateFnsUtils from "@date-io/date-fns";
import DatePicker from "react-datepicker";
import { makeStyles } from "@material-ui/core/styles";
import TabPanel from "../tab-panel";

import "react-datepicker/dist/react-datepicker.css";

import "./app-form.scss";
import AcademicDetails from "../academic-details";
import Navigation from "../navigation";
import updateAction from "../update-action";

const defaultValues = {
  Native: "",
  TextField: "",
  Select: "",
  title: { value: "mr", label: "Mr" },
  Checkbox: false,
  switch: false,
  ReactDatepicker: new Date(),
  RadioGroup: "",
  numberFormat: 123456789,
  downShift: "apple",
};

function ApplicationForm(props) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();
  const { actions, state } = useStateMachine({ updateAction });

  const [value, setValue] = useState(0);
  const [isSouthAfrican, setIsSouthAfrican] = useState(true);
  const [nationality, setNationality] = useState(0);
  const [sameAddress, setSameAddress] = useState(0);
  const [isOther, setIsOther] = useState(false);
  const [haveMedicalAid, setHaveMedicalAid] = useState(false);
  const [disabled, setDisabled] = useState(0);
  const [roomWithFride, setRoomWithFridge] = useState(0);
  const [kovascBefore, setKovascBefore] = useState(0);
  const [legalAddressAccept, setLegalAddressAccept] = useState(false);

  const onSubmit = (data) => {
    console.log(JSON.stringify(data));
    actions.updateAction(data);
    console.log('on submit');
    props.history.push("./academic-information");
  };

  const handleNationality = (event) => {
    setNationality(event.target.value);
    event.target.value == "0"
      ? setIsSouthAfrican(true)
      : setIsSouthAfrican(false);
  };

  const handleSameAddress = (checked) => {
    checked ? setSameAddress(1) : setSameAddress(0);
  };

  const handleRoomWithFridge = (checked) => {
    checked ? setRoomWithFridge(1) : setRoomWithFridge(0);
  };

  const handleKovascBefore = (checked) => {
    checked ? setKovascBefore(1) : setKovascBefore(0);
  };

  const handleMedicalAid = (checked) => {
    checked ? setHaveMedicalAid(1) : setHaveMedicalAid(0);
  };

  const handleLegalAddress = (event) => {
    event.target.value === "2" ? setIsOther(true) : setIsOther(false);
  };

  const handleLegalAddressAccept = (checked) => {
    checked ? setLegalAddressAccept(true) : setLegalAddressAccept(false);
  };

  const handleDisability = (checked) => {
    checked ? setDisabled(1) : setDisabled(0);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div>
        <Navigation />
        <TabPanel value={value} index={0}>
          <Form.Row>
            <Container>
              <Row>
                <Col xs={12} md={3}>
                  <section className="personal-section-wrap">
                    <section className="room-preference-wrap">
                      <div className="form-group">
                        <label htmlFor="sel1">Title</label>
                        <select className="form-control" name="personal_title" id="personal_title" 
                         {...register("personal_title", {
                          required: true,
                          })}
                          deafultvalue={state.personal_title}
                          >
                          <option value="0">Mr</option>
                          <option value="1">Ms</option>
                          <option value="2">Mrs</option>
                        </select>
                      </div>
                    </section>
                    
                    <section>
                      <label htmlFor="personal_surname">Surname</label>
                      <input
                        {...register("personal_surname", {
                          required: true,
                          maxLength: 80,
                        })}
                        name="personal_surname"
                      />
                      {errors.personal_first_name && (
                        <p className="error">Your surname is required</p>
                      )}
                    </section>

                    <section>
                      <label htmlFor="firstName">First Name</label>
                      <input
                        {...register("personal_first_name", {
                          required: true,
                          maxLength: 80,
                        })}
                        name="personal_first_name"
                      />
                      {errors.personal_first_name && (
                        <p className="error">Your first name is required</p>
                      )}
                    </section>
                    <section className="room-preference-wrap">
                      <div className="form-group">
                        <label htmlFor="sel1">Nationality</label>
                        <select className="form-control" name="personal_sa_citizen" id="personal_sa_citizen" 
                         {...register("personal_sa_citizen", {
                          required: true,
                          })}
                          deafultvalue={state.personal_sa_citizen}
                          onClick={(e) => {
                            handleNationality(e);
                          }}
                          >
                          <option value="0">South African</option>
                          <option value="1">Non-South African</option>
                        </select>
                      </div>
                      {errors.personal_sa_citizen && (
                        <p className="error">Please select your nationality</p>
                      )}
                    </section>
                    <section className="id-number">
                      {isSouthAfrican ?
                        <label htmlFor="personal_id_number">SA ID Number</label> :
                        <label htmlFor="passportNumber">Passport Number</label>}
                      <input
                        type="number"
                        name="personal_id_number"
                        {...register("personal_id_number", {
                          required: true,
                          maxLength: 80,
                        })}
                      />
                      {errors.personal_id_number && (
                        <p className="error">
                          Please enter your valid SA ID Number
                        </p>
                      )}
                    </section>

                    <section>
                        <label htmlFor="birthday">Date of Birth</label>
                        <input type="date"
                          id="personal_dob" name="personal_dob"
                          {...register("personal_dob", {
                            required: true,
                          })}></input>
                      {errors.personal_dob && (
                        <p className="error">
                          Please specify your date of birth
                        </p>
                      )}
                    </section>
                  </section>
                  <section className="personal-section-wrap-two">
                    <section className="room-preference-wrap">
                      <label>Marital Status</label>
                      <select className="form-control" name="personal_title" id="personal_title"
                        {...register("personal_marital_status", {
                          required: true,
                        })}
                        deafultvalue={state.personal_marital_status}
                      >
                        <option value="0">Single</option>
                        <option value="1">Married</option>
                      </select>
                      {errors.personal_marital_status && (
                        <p className="error">
                          Please select your marital status
                        </p>
                      )}
                    </section>
                    <section className="room-preference-wrap">
                      <label>Gender</label>
                      <select className="form-control" name="personal_gender" id="personal_gender"
                        {...register("personal_gender", {
                          required: true,
                        })}
                        deafultvalue={state.personal_gender}
                      >
                        <option value="0">Female</option>
                        <option value="1">Male</option>
                      </select>

                      {errors.personal_gender && (
                        <p className="error">Please select your gender</p>
                      )}
                    </section>
                    <section className="disabled-check">
                      <input
                        type="checkbox"
                        id="personal_disablity_status"
                        name="personal_disablity_status"
                        {...register("personal_disablity_status", {
                          required: false,
                        })}
                        onClick={(e) => {
                          handleDisability(e.target.checked)
                        }}
                      />
                      <label>Are you disbaled ? </label>
                    </section>
                    {disabled ?
                      <section className="disabled-desc">
                        <section>
                          <label>
                            Disability description
                          </label>
                          <textarea
                            id="personal_disability_description"
                            name="personal_disability_description"
                            rows="4"
                            cols="50"
                            maxLength="200"
                            {...register("personal_disability_description", { required: true })}
                          ></textarea>
                          {errors.disabilityDesc && (
                            <p className="error">Disability description is required</p>
                          )}
                        </section>
                      </section>:''
                    }
                    <section className="room-preference-wrap">
                      <label>Room preference</label>
                      <select className="form-control" name="personal_room_preference" id="personal_room_preference"
                        {...register("personal_room_preference", {
                          required: true,
                        })}
                        deafultvalue={state.personal_room_preference}
                      >
                        <option value="0">Single room shared en suite</option>
                        <option value="1">Single room communal bathroom</option>
                        <option value="2">Double room shared en suite</option>
                        <option value="3">Disable room en suite</option>
                        <option value="4">Corner room shared en suite</option>
                      </select>
                      {errors.personal_room_preference && (
                        <p className="error">
                          Please select your prefered room
                        </p>
                      )}
                    </section>
                    <section>
                      <section className="same-address-check">
                     <input
                        type="checkbox"
                        id="personal_room_with_fridge"
                        name="personal_room_with_fridge"
                        {...register("personal_room_with_fridge", {
                          required: false,
                        })}
                        onClick={(e) => {
                          handleRoomWithFridge(e.target.checked)
                        }}
                      />

                    <label>
                     Room with Fridge ?
                    </label>
                  </section>
                    </section>
                    <section className="app-year-wrap">
                      <label>Which year are you applying for?</label>
                        <select className="form-control" name="personal_application_year" id="personal_application_year"
                        {...register("personal_application_year", {
                          required: true,
                        })}
                        deafultvalue={state.personal_application_year}
                      >
                        <option value="0">2021</option>
                        <option value="1">2022</option>
                      </select>
                      {errors.personal_application_year && (
                        <p className="error">
                          Please select a year you applying for
                        </p>
                      )}
                    </section>
                    <section className="app-year-wrap">
                      <label>Which semester are you applying for</label>
                       <select className="form-control" name="personal_semester_year" id="personal_semester_year"
                        {...register("personal_semester_year", {
                          required: true,
                        })}
                        deafultvalue={state.personal_semester_year}
                      >
                        <option value="0">Full year</option>
                        <option value="1">1st Semester</option>
                        <option value="2">2nd Semester</option>
                      </select>
                      {errors.personal_semester_year && (
                        <p className="error">
                          Please select a semseter you applying for
                        </p>
                      )}
                    </section>
                    <section>
                      <label>
                        Expected date of arrival at Kovacs Residence
                      </label>
                       <input type="date"
                          id="personal_kovacs_arrival_date" name="personal_kovacs_arrival_date"
                          {...register("personal_kovacs_arrival_date", {
                            required: true,
                          })}></input>
                    </section>
                  </section>
                </Col>
                <Col xs={12} md={3}>
                  <section className="home-address">
                    <section>
                      <label>
                        Home address (Street Name and Number/Unit/Complex) :{" "}
                      </label>
                   
                        <textarea 
                        id="personal_home_street_address" 
                        name="personal_home_street_address" 
                        rows="4" 
                        cols="50"
                        maxLength="200"
                        {...register("personal_home_street_address", { required: true })}
                        ></textarea>
                      {errors.personal_home_street_address && (
                        <p className="error">Your home address is required</p>
                      )}
                    </section>

                    <section>
                      <label htmlFor="personal_home_province">Province</label>
                      <input
                        {...register("personal_home_province", {
                          required: true,
                          maxLength: 80,
                        })}
                        name="personal_home_province"
                      />
                      {errors.personal_home_province && (
                        <p className="error">Your province is required</p>
                      )}
                    </section>

                    <section>
                      <label htmlFor="personal_home_postal_code">Postal Code</label>
                      <input
                        {...register("personal_home_postal_code", {
                          required: true,
                          maxLength: 80,
                        })}
                      />
                      {errors.personal_home_postal_code && (
                        <p className="error">Your Postal Code is required</p>
                      )}
                    </section>
                    <section>
                      <label htmlFor="personal_home_city">City</label>
                      <input
                        type="text"
                        {...register("personal_home_city", {
                          required: true,
                          maxLength: 80,
                        })}
                      />
                      {errors.city && (
                        <p className="error">Your city is required</p>
                      )}
                    </section>

                    <section>
                      <label htmlFor="personal_home_country">Country</label>
                      <input
                        placeholder="Country"
                        {...register("personal_home_country", {
                          required: true,
                        })}
                      />
                      {errors.personal_home_country && (
                        <p className="error">Your Country is required</p>
                      )}
                    </section>
                  </section>
                  <section className="same-address-check">
                    <input
                      type="checkbox"
                      id="resSameAsHome"
                      name="resSameAsHome"
                      // {...register("resSameAsHome", {
                      //   required: false,
                      // })}
                      onClick={(e) => {
                        handleSameAddress(e.target.checked)
                      }}
                    />
                    <label>
                      Current residential address is the same as home address
                      above{" "}
                    </label>
                  </section>
                  {!sameAddress ? (
                    <section className="residential-address">
                      <section>
                        <label>
                          Current residential address (Street Name and
                          Number/Unit/Complex) if differs from above
                        </label>
                        <textarea 
                        id="personal_current_street_address" 
                        name="personal_current_street_address" 
                        rows="4" 
                        cols="50"
                        maxLength="200"
                        {...register("personal_current_street_address", { required: true })}
                        >

                        </textarea>
                        {errors.personal_current_street_address && (
                          <p className="error">Your current residential address is required</p>
                        )}
                      </section>
                      <section>
                        <label htmlFor="personal_current_province">Province</label>
                        <input
                          {...register("personal_current_province", { required: true })}
                          name="personal_current_province"
                        />
                        {errors.personal_current_province && (
                          <p className="error">Your province is required</p>
                        )}
                      </section>
                      <section>
                        <label htmlFor="personal_current_postal_code">Postal Code</label>
                        <input
                          type="number"
                          {...register("personal_current_postal_code", {
                            required: true,
                          })}
                          name="personal_current_postal_code"
                        />
                        {errors.personal_current_postal_code && (
                          <p className="error">Your Postal Code is required</p>
                        )}
                      </section>
                      <section>
                        <label htmlFor="personal_current_city">City</label>
                        <input
                          placeholder="City"
                          {...register("personal_current_city", {
                            required: true,
                          })}
                        />
                        {errors.personal_current_city && (
                          <p className="error">Your City is required</p>
                        )}
                      </section>
                      <section>
                        <label htmlFor="personal_current_country">Country</label>
                        <input
                          placeholder="Country"
                          {...register("personal_current_country", {
                            required: true,
                          })}
                        />
                        {errors.personal_current_country && (
                          <p className="error">Your country is required</p>
                        )}
                      </section>
                    </section>
                  ) : (
                    ""
                  )}
                  <section className="legal-address-wrap">
                    <section>
                      <label>
                        Please indicate which address will be used for legal
                        purposes on the lease agreement
                      </label>
                        <select className="form-control" name="personal_semester_year" id="personal_semester_year"
                        {...register("personal_semester_year", {
                          required: true,
                        })}
                        deafultvalue={state.personal_semester_year}
                        onChange={(e) => {
                          handleLegalAddress(e);
                        }}
                      >
                        <option value="0">Home Address</option>
                        <option value="1">Current Residential Address</option>
                        <option value="2">Other</option>
                      </select>
                      {errors.personal_legal_address_choice && (
                        <p className="error">
                          Please select an addres for legal purposes
                        </p>
                      )}
                    </section>
                    <section className="same-address-check">
              
                   <input
                      type="checkbox"
                      id="personal_kovacs_legal_address_accept"
                      name="personal_kovacs_legal_address_accept"
                      {...register("personal_kovacs_legal_address_accept", {
                        required: false,
                      })}
                      onClick={(e) => {
                        handleLegalAddressAccept(e.target.checked)
                      }}
                    />
                    <label>
                    For legal purposes your Kovacs Residence address will be used on the lease agreement
                    </label>
                  </section>

                    {isOther ? (
                      <section className="legal-address">
                      
                        <TextField
                          multiline
                          rows={5}
                          rowsMax={5}
                          {...register("personal_legal_street_address", { required: true })}
                          name="personal_legal_street_address"
                        />
                        {errors.Address && (
                          <p className="error">Your address is required</p>
                        )}
                        <section>
                        <label htmlFor="personal_legal_province">Province</label>
                        <input
                          {...register("personal_legal_province", { required: true })}
                          name="personal_legal_province"
                        />
                        {errors.personal_legal_province && (
                          <p className="error">Your province is required</p>
                        )}
                      </section>
                      <section>
                        <label htmlFor="personal_legal_postal_code">Postal Code</label>
                        <input
                          type="number"
                          {...register("personal_legal_postal_code", {
                            required: true,
                          })}
                          name="personal_legal_postal_code"
                        />
                        {errors.personal_legal_postal_code && (
                          <p className="error">Your Postal Code is required</p>
                        )}
                      </section>
                      <section>
                        <label htmlFor="personal_legal_city">City</label>
                        <input
                          placeholder="City"
                          {...register("personal_legal_city", {
                            required: true,
                          })}
                        />
                        {errors.personal_legal_city && (
                          <p className="error">Your City is required</p>
                        )}
                      </section>
                      <section>
                        <label htmlFor="personal_legal_country">Country</label>
                        <input
                          placeholder="Country"
                          {...register("personal_legal_country", {
                            required: true,
                          })}
                        />
                        {errors.personal_legal_country && (
                          <p className="error">Your country is required</p>
                        )}
                      </section>
                      </section>
                    ) : (
                      ""
                    )}
                  </section>
                </Col>
                <Col xs={12} md={3}>
                <section className="applicant-landline">
                    <section>
                      <label htmlFor="personal_applicant_telephone">Applicant landline</label>
                      <input
                        type="number"
                        {...register("personal_applicant_telephone", {
                          required: false,
                        })}
                      />
                      {errors.personal_applicant_telephone && (
                        <p className="error">Applicant landline is required</p>
                      )}
                    </section>
                    <section>
                      <label htmlFor="personal_applicant_telephone_country_code">Country dial code</label>
                      <input
                        type="number"
                        {...register("personal_applicant_telephone_country_code", {
                          required: false,
                        })}
                      />
                    </section>
                    <section>
                      <label htmlFor="personal_applicant_telephone_area_code">Area code</label>
                      <input {...register("personal_applicant_telephone_area_code", { required: false })} />
                      {errors.personal_applicant_telephone_area_code && (
                        <p className="error">Your Area code is required</p>
                      )}
                    </section>
                  </section>
                  <section className="applicant-mobile">
                    <section>
                      <label htmlFor="personal_applicant_mobile">Applicant mobile</label>
                      <input
                        placeholder=""
                        {...register("personal_applicant_mobile", {
                          required: true,
                        })}
                      />
                      {errors.personal_applicant_mobile && (
                        <p className="error">Your mobile number is required </p>
                      )}
                    </section>
                    <section>
                      <label htmlFor="personal_applicant_mobile_country_code">Country dial code</label>
                      <input type="number" {...register("personal_applicant_mobile_country_code", {})} />
                    </section>
                    <section>
                      <label htmlFor="personal_applicant_mobile_area_code">Area code</label>
                      <input {...register("personal_applicant_mobile_area_code", {})} />
                    </section>
                  </section>
                  <section className="applicant-fax section">
                    <section>
                      <label htmlFor="personal_applicant_fax">Applicant Fax number</label>
                      <input
                        placeholder="Country"
                        {...register("personal_applicant_fax", {
                        })}
                      />
                    </section>
                    <section>
                      <label htmlFor="personal_applicant_fax_country_code">Country dial code</label>
                      <input
                        type="number"
                        {...register("personal_applicant_fax_country_code", {
                          required: true,
                        })}
                        name="personal_applicant_fax_country_code"
                      />
                      {errors.personal_applicant_fax_country_code && (
                        <p className="error">Your country code is required</p>
                      )}
                    </section>
                    <section>
                      <label htmlFor="personal_applicant_fax_area_code">Area code</label>
                      <input
                        placeholder="Country"
                        {...register("personal_applicant_fax_area_code", {
                          required: true,
                        })}
                      />
                      {errors.personal_applicant_fax_area_code && (
                        <p className="error">Your Area code is required</p>
                      )}
                    </section>
                  </section>
                  <section className="applicant-email">
                    <label htmlFor="personal_applicant_email">Applicant Email</label>
                    <input
                      {...register("personal_applicant_email", {
                        required: false,
                      })}
                    />
                  </section>
                  <section className="parent-landline section">
                    <section>
                      <label htmlFor="personal_parent_telephone">
                        Parent or Guardian landline
                      </label>
                      <input
                        {...register("personal_parent_telephone", {
                          required: true,
                        })}
                      />
                      {errors.personal_parent_telephone && (
                        <p className="error">
                          Please enter your parent/guardian's number
                        </p>
                      )}
                    </section>
                    <section>
                      <label htmlFor="personal_parent_telephone_country_code">Country dial code</label>
                      <input
                        type="number"
                        {...register("personal_parent_telephone_country_code", {
                          required: true,
                        })}
                      />
                      {errors.personal_parent_telephone_country_code && (
                        <p className="error">Your country code is required</p>
                      )}
                    </section>
                    <section>
                      <label htmlFor="personal_parent_telephone_area_code">Area code</label>
                      <input
                        placeholder="Area Code"
                        {...register("personal_parent_telephone_area_code", {
                          required: true,
                        })}
                      />
                      {errors.personal_parent_telephone_area_code && (
                        <p className="error">Your Area code is required</p>
                      )}
                    </section>
                  </section>
                </Col>
                <Col xs={12} md={3}>
                  <section className="parent-mobile section">
                    <section>
                      <label htmlFor="personal_parent_mobile">
                        Parent or Guardian Mobile
                      </label>
                      <input
                        {...register("personal_parent_mobile", {
                          required: true,
                        })}
                      />
                      {errors.personal_parent_mobile && (
                        <p className="error">
                          Please enter your parent/guardian's mobile number
                        </p>
                      )}
                    </section>
                    <section>
                      <label htmlFor="personal_parent_mobile_country_code">Country dial code</label>
                      <input
                        type="number"
                        {...register("personal_parent_mobile_country_code", {
                          required: true,
                        })}
                      />
                      {errors.personal_parent_mobile_country_code && (
                        <p className="error">Your country code is required</p>
                      )}
                    </section>
                    <section>
                      <label htmlFor="personal_parent_mobile_area_code">Area code</label>
                      <input
                        {...register("personal_parent_mobile_area_code", {
                          required: true,
                        })}
                      />
                      {errors.personal_parent_mobile_area_code && (
                        <p className="error">Your Area code is required</p>
                      )}
                    </section>
                  </section>

                  <section className="parent-fax-number section">
                    <section>
                      <label htmlFor="personal_parent_fax">
                        Parent or Guardian Fax number
                      </label>
                      <input
                        {...register("personal_parent_fax", {
                          // required: true,
                        })}
                      />
                      {errors.personal_parent_fax && (
                        <p className="error">
                          Please enter your parent/guardian's fax number
                        </p>
                      )}
                    </section>
                    <section>
                      <label htmlFor="personal_parent_fax_country_code">Country dial code</label>
                      <input
                        type="number"
                        {...register("personal_parent_fax_country_code", {
                          // required: true,
                        })}
                      />
                      {errors.personal_parent_fax_country_code && (
                        <p className="error">Your country code is required</p>
                      )}
                    </section>
                    <section>
                      <label htmlFor="personal_parent_fax_area_code">Area code</label>
                      <input
                        {...register("personal_parent_fax_area_code", {
                          required: true,
                        })}
                      />
                      {errors.personal_parent_fax_area_code && (
                        <p className="error">Your Area code is required</p>
                      )}
                    </section>
                  </section>

                  <section className="applicant-email section">
                    <label htmlFor="personal_parent_email">
                      Parent or Guardian Email address
                    </label>
                    <input
                      {...register("personal_parent_email", {
                        required: false,
                      })}
                    />
                  </section>
                  <section className="previous-residency-check">
                     <input
                      type="checkbox"
                      id="personal_kovacs_before"
                      name="personal_kovacs_before"
                      {...register("personal_kovacs_before", {
                        required: false,
                      })}
                      onClick={(e) => {
                        handleKovascBefore(e.target.checked)
                      }}
                    />
                    <label>Previously resided at Kovacs</label>
                  </section>
                  <section className="medical-aid-check">
                     <input
                      type="checkbox"
                      id="personal_medical_aid"
                      name="personal_medical_aid"
                      {...register("personal_medical_aid", {
                        required: false,
                      })}
                      onClick={(e) => {
                        handleMedicalAid(e.target.checked)
                      }}
                    />
                    <label>Have medical Aid </label>
                  </section>
                  {haveMedicalAid ? (
                    <section className="medical-aid-details section">
                      <section className="medical-aid-number">
                        <label htmlFor="personal_medical_aid_number">
                          Medical Aid number
                        </label>
                        <input
                          type="number"
                          {...register("personal_medical_aid_number", {
                            required: true,
                          })}
                        />
                        {errors.personal_medical_aid_number && (
                          <p className="error">
                            Your medical aid number required
                          </p>
                        )}
                      </section>
                      <section className="medical-aid-name">
                        <label htmlFor="personal_medical_aid_name">
                          Medical Aid name
                        </label>
                        <input
                          type="text"
                          {...register("personal_medical_aid_name", {
                            required: true,
                          })}
                        />
                        {errors.personal_medical_aid_name && (
                          <p className="error">
                            Your medical aid name is required
                          </p>
                        )}
                      </section>
                      <section className="medical-aid-member-name">
                        <label htmlFor="personal_medical_aid_member_name">
                          Main member's name
                        </label>
                        <input
                          type="text"
                          {...register("personal_medical_aid_member_name", {
                            required: true,
                          })}
                        />
                        {errors.personal_medical_aid_member_name && (
                          <p className="error">
                            Your medical aid main memeber name is required
                          </p>
                        )}
                      </section>
                      <section className="medical-aid-member-surname">
                        <label htmlFor="personal_medical_aid_member_surname">Surname</label>
                        <input
                          type="text"
                          {...register("personal_medical_aid_member_surname", {
                            required: true,
                          })}
                        />
                        {errors.personal_medical_aid_member_surname && (
                          <p className="error">
                            Your medical aid main memeber surname is required
                          </p>
                        )}
                      </section>
                      <section>
                        <label>
                          Any important info regarding illnesses or allergies
                          (If you do not have any, please enter the word NONE)
                        </label>
                    
                         <textarea
                            id="personal_illness_allergy"
                            name="personal_illness_allergy"
                            rows="4"
                            cols="50"
                            maxLength="200"
                            {...register("personal_illness_allergy", { required: false })}
                          ></textarea>
                      </section>
                    </section>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>
            </Container>
          </Form.Row>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <AcademicDetails />
        </TabPanel>
        <Row className="submit-wrapper">
          <Col>
            <input className="submit-btn" type="submit" value="Next" />
          </Col>
        </Row>
      </div>
    </Form>
  );
}

export default withRouter(ApplicationForm);
