import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import {
  Checkbox,
  Select,
  MenuItem,
  Button,
} from "@material-ui/core";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import TabPanel from "../tab-panel";

import "react-datepicker/dist/react-datepicker.css";
import Navigation from "../navigation";
import "./academic-details.scss";
import { useStateMachine } from "little-state-machine";
import updateAction from "../update-action";

const defaultValues = {

};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
}));

function AcademicDetails(props) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    
  } = useForm({ defaultValues });

  const { actions, state } = useStateMachine({ updateAction });
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [residenceApp, setResidenceApp] = useState(0);
  const [residenceAppElseWhere, setResidenceAppElseWhere] = useState(0);
  
  const onSubmit = (data) => {
    console.log(JSON.stringify(data));
    actions.updateAction(data);
    console.log('on submit');
    props.history.push("./fees-management");
  };

  const handleResidenceApp= (checked) => {
    checked ? setResidenceApp(1) : setResidenceApp(0);
  };

  const handleResidenceAppElseWhere= (checked) => {
    checked ? setResidenceAppElseWhere(1) : setResidenceAppElseWhere(0);
  };

  return (
    <>
      <Navigation />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className={classes.root}>
          <AppBar position="static" color="default"></AppBar>
          {/* <TabPanel value={value} index={1}> */}
            <Form.Row>
              <Container>
                <Row className="academic-details-wrapper">
                  <Col xs={12} md={3}>
                    <section className="section">
                      <section>
                        <label htmlFor="personal_room_with_fridge">
                          Highest qualification obtained
                        </label>
                        <input
                          {...register("personal_room_with_fridge", {
                            required: true,
                          })}
                        />
                        {errors.personal_room_with_fridge && (
                          <p className="error">Your Highest Qualification is required!</p>
                        )}
                      </section>
                      <section>
                        <label htmlFor="academic_uwc_student_number">
                          UWC student number if previously registered
                        </label>
                        <input
                          type="number"
                          {...register("academic_uwc_student_number", {
                            required: false,
                          })}
                        />
                      </section>
                      <section>
                        <label htmlFor="academic_uwc_study_programme">
                          UWC program you are registered for 2021
                        </label>
                        <input
                          {...register("academic_uwc_study_programme", {
                            required: false,
                          })}
                        />
                        
                      </section>
                      <section className="app-year-wrap">
                      <div className="form-group">
                        <label htmlFor="sel1"> What year of study are you registering for in 2021</label>
                        <select className="form-control" name="academic_study_year" id="academic_study_year"
                          {...register("academic_study_year", {
                            required: true,
                          })}
                          deafultvalue={state.academic_study_year}
                        >
                          <option value="0">1st</option>
                          <option value="1">2nd</option>
                          <option value="2">3rd</option>
                          <option value="3">4th</option>
                          <option value="4">Honours</option>
                          <option value="5">Masters</option>
                          <option value="6">Post PHD</option>
                          <option value="7">UWC Staff</option>
                        </select>
                      </div>

                        {errors.academic_study_year && (
                          <p className="error">
                            Please select year of study
                          </p>
                        )}
                      </section>
                    </section>
                    
                  </Col>
                  <Col xs={12} md={3}>
                    <section className="section">
                    
                      <section className="app-year-wrap">
                          <div className="form-group">
                        <label htmlFor="sel1">Duration of your programme</label>
                        <select className="form-control" name="academic_remaining_years" id="academic_remaining_years"
                          {...register("academic_remaining_years", {
                            required: true,
                          })}
                          deafultvalue={state.academic_remaining_years}
                        >
                          <option value="0">1</option>
                          <option value="1">2</option>
                          <option value="2">3</option>
                          <option value="3">4</option>
                          <option value="4">5</option>
                        </select>
                      </div>
                        {errors.academic_remaining_years && (
                          <p className="error">
                            Please select a semseter you applying for
                          </p>
                        )}
                      </section>
                      <section className="app-year-wrap">
                      <div className="form-group">
                        <label>
                          What is the current status of application for study at
                          UWC
                        </label>
                        <select className="form-control" name="academic_application_status" id="academic_application_status"
                          {...register("academic_application_status", {
                            required: true,
                          })}
                          deafultvalue={state.academic_application_status}
                        >
                          <option value="0">Provisional acceptance</option>
                          <option value="1">Final acceptance</option>
                          <option value="2">No acceptance yet</option>
                        </select>
                      </div>
                        {errors.Semester && (
                          <p className="error">
                            Please select a application status you applying for
                          </p>
                        )}
                      </section>
                      <section>
                        <label htmlFor="academic_pg_start_date">Start date</label>
                        <input type="date"
                          id="personal_dob" name="academic_pg_start_date"
                          {...register("academic_pg_start_date", {
                            required: true,
                          })}></input>
                      {errors.academic_pg_start_date && (
                        <p className="error">
                          Please specify your start date
                        </p>
                      )}
                    </section>

                    <section>
                        <label htmlFor="academic_pg_end_date">End date</label>
                        <input type="date"
                          id="personal_dob" name="academic_pg_end_date"
                          {...register("academic_pg_end_date", {
                            required: true,
                          })}></input>
                      {errors.academic_pg_end_date && (
                        <p className="error">
                          Please specify your end date
                        </p>
                      )}
                    </section>
                    </section>
                    <section className="section">
                      <section className="same-address-check">
                      <input
                        type="checkbox"
                        id="academic_uwc_residence_application"
                        name="academic_uwc_residence_application"
                        {...register("academic_uwc_residence_application", {
                          required: false,
                        })}
                        onClick={(e) => {
                          handleResidenceApp(e.target.checked)
                        }}
                      />
                        <label>
                          Have you applied at UWC Residence for 2021?
                        </label>
                      </section>
                      <section className="same-address-check">
                      <input
                        type="checkbox"
                        id="academic_study_application_elsewhere"
                        name="academic_study_application_elsewhere"
                        {...register("academic_study_application_elsewhere", {
                          required: false,
                        })}
                        onClick={(e) => {
                          handleResidenceAppElseWhere(e.target.checked)
                        }}
                      />
                        <label>
                          Have you applied for Residence elsewhere for 2021?
                        </label>
                      </section>
                    </section>
                  </Col>
                </Row>
              </Container>
            </Form.Row>
            <Row className="academic-btn-wrapper">
              <Col>
                <Button className="submit-btn" type="submit">Next</Button>
              </Col>
            </Row>
          {/* </TabPanel> */}
        </div>
      </Form>
    </>
  );
}

export default withRouter(AcademicDetails);
