import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Select,
  MenuItem,
  Button,
} from "@material-ui/core";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import TabPanel from "../tab-panel";

import "react-datepicker/dist/react-datepicker.css";
import Navigation from "../navigation";

const defaultValues = {
  Native: "",
  TextField: "",
  Select: "",
  title: { value: "mr", label: "Mr" },
  Checkbox: false,
  switch: false,
  ReactDatepicker: new Date(),
  RadioGroup: "",
  numberFormat: 123456789,
  downShift: "apple",
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}));

function BursaryManagementFee() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({ defaultValues });

  const onSubmit = (data) => {
    console.log(JSON.stringify(data));
  };

  const classes = useStyles();
  const [value, setValue] = useState(0);

  return (
    <>
      <Navigation />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className={classes.root}>
          <AppBar position="static" color="default"></AppBar>
          <TabPanel value={value} index={0}>
            <Form.Row>
              <Container>
                <Row className="academic-details-wrapper">
                  <Col xs={12} md={3}>
                    <section className="section">
                      <section>
                        <label htmlFor="bursaryAmount">
                          What is the annual value of your bursary for 2021
                        </label>
                        <input
                          type="number"
                          {...register("bursaryAmount", {
                            required: false,
                          })}
                        />
                        {/* {errors.bursarEmailAddress && (
                          <p>Your Bursar Email Address is required!</p>
                        )} */}
                      </section>
                      <section>
                        <label htmlFor="bursaryAccomodationAmount">
                          How much of the total value of your bursary is
                          allocated to accommodation
                        </label>
                        <input
                          type="number"
                          {...register("bursaryAccomodationAmount", {
                            required: false,
                          })}
                        />
                        {/* {errors.bursarEmailAddress && (
                          <p>Your Bursar Email Address is required!</p>
                        )} */}
                      </section>
                      <section className="app-year-wrap">
                        <label>What is the status of your bursary</label>
                        <Controller
                          render={({ field }) => (
                            <Select {...field} className="app-year" id="">
                              <MenuItem value="0">Approved</MenuItem>
                              <MenuItem value="1">Awaiting approval</MenuItem>
                            </Select>
                          )}
                          name="bursayStatus"
                          control={control}
                          control={control}
                          {...register("bursayStatus", {
                            required: false,
                          })}
                        />
                        {/* {errors.Semester && (
                          <p className="error">
                            Please select a semseter you applying for
                          </p>
                        )} */}
                      </section>

                      <section>
                        <label>
                          What is the payment date on which the bursar will pay
                          your residence fees
                        </label>
                        <MuiPickersUtilsProvider
                          utils={MomentUtils}
                          className="form-date-picker-wrap"
                        >
                          <Controller
                            name="MUIPicker"
                            className="form-date-picker"
                            control={control}
                            render={({ field: { ref, ...rest } }) => (
                              <KeyboardDatePicker
                                margin="normal"
                                id="date-picker-dialog"
                                label="Date picker dialog"
                                format="MM/dd/yyyy"
                                KeyboardButtonProps={{
                                  "aria-label": "change date",
                                }}
                                {...rest}
                              />
                            )}
                          />
                        </MuiPickersUtilsProvider>
                      </section>
                      <section className="app-year-wrap">
                        <label>
                          Will the bursar pay Kovacs directly or via your UWC
                          student account
                        </label>
                        <Controller
                          render={({ field }) => (
                            <Select {...field} className="app-year" id="">
                              <MenuItem value="0">Kovas</MenuItem>
                              <MenuItem value="1">UWC</MenuItem>
                            </Select>
                          )}
                          name="bursayStatus"
                          control={control}
                          control={control}
                          {...register("bursayStatus", {
                            required: false,
                          })}
                        />
                        {/* {errors.Semester && (
                          <p className="error">
                            Please select a semseter you applying for
                          </p>
                        )} */}
                      </section>
                    </section>
                  </Col>
                </Row>
              </Container>
            </Form.Row>
            <Row className="academic-btn-wrapper">
              <Col>
                {/* <input className="submit-btn" type="button" /> */}
                <Button className="submit-btn">Next</Button>
              </Col>
            </Row>
          </TabPanel>
        </div>
      </Form>
    </>
  );
}

export default BursaryManagementFee;
