import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Link,useLocation } from "react-router-dom";

function Navigation() {
  function a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      "aria-controls": `scrollable-auto-tabpanel-${index}`,
    };
  }

  const location = useLocation();

  return (
    <>
    <nav className="container">

        <ul className="steps">
        <li className="kovacs-logo">
            <Link to="/"><img src="./kvs-1.png" /></Link>
          </li>
          <li >
            <Link className={location.pathname === "/" ? "active" : ""} to="/">Application Information</Link>
          </li>
          <li >

            <Link className={location.pathname === "/personal-information" ? "active" : ""} to="/personal-information">Personal Information</Link>
          </li>

          <li >
            <Link className={location.pathname === "/academic-information" ? "active" : ""} to="/academic-information">Academic Details</Link>
          </li>
          <li >
            <Link className={location.pathname === "/fees-management" ? "active" : ""} to="/fees-management">Fees management</Link>
          </li>
          <li>
            <Link className={location.pathname === "/credit-reference" ? "active" : ""} to="/credit-reference">Credit reference</Link>
          </li>
          <li>
            <Link className={location.pathname === "/bursary-management-fee" ? "active" : ""} to="/bursary-management-fee">Bursary Fee Management</Link>
          </li>
           <li >
            <Link className={location.pathname === "/application-submission" ? "active" : ""} to="/application-submission">Application submission</Link>
          </li>
        </ul>
      </nav>
    </>
  );
}

export default Navigation;
