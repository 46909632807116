import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Checkbox,
  Select,
  MenuItem,
  Button,
} from "@material-ui/core";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import TabPanel from "../tab-panel";

import "react-datepicker/dist/react-datepicker.css";
import Navigation from "../navigation";

const defaultValues = {
  Native: "",
  TextField: "",
  Select: "",
  title: { value: "mr", label: "Mr" },
  Checkbox: false,
  switch: false,
  ReactDatepicker: new Date(),
  RadioGroup: "",
  numberFormat: 123456789,
  downShift: "apple",
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}));

function CreditReference() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    methods,
  } = useForm({ defaultValues });

  const onSubmit = (data) => {
    console.log(JSON.stringify(data));
  };

  const classes = useStyles();
  const [value, setValue] = useState(0);

  return (
    <>
      <Navigation />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className={classes.root}>
          <AppBar position="static" color="default"></AppBar>
          <TabPanel value={value} index={0}>
            <Form.Row>
              <Container>
                <Row className="academic-details-wrapper">
                  <Col xs={12} md={3}>
                    <section className="section">
                      <section className="app-year-wrap">
                        <label>
                          If you are studying at UWC already, where are you
                          currently residing
                        </label>
                        <Controller
                          render={({ field }) => (
                            <Select
                              {...field}
                              className="app-year"
                              id="ApplicationApplicantCreditCurrentResidence"
                            >
                              <MenuItem value="0">In UWC residence</MenuItem>
                              <MenuItem value="1">
                                Private Accomodation
                              </MenuItem>
                              <MenuItem value="2">Family</MenuItem>
                            </Select>
                          )}
                          name="Semester"
                          control={control}
                          control={control}
                          {...register("Semester", {
                            required: false,
                          })}
                        />
                        {/* {errors.Semester && (
                          <p className="error">
                            Please select a semseter you applying for
                          </p>
                        )} */}
                      </section>
                      <section>
                        <label htmlFor="rentalAmount">
                          What rental amount did you pay per month (if none,
                          state 0)
                        </label>
                        <input
                          type="number"
                          {...register("rentalAmount", {
                            required: false,
                          })}
                        />
                        {/* {errors.bursarEmailAddress && (
                          <p>Your Bursar Email Address is required!</p>
                        )} */}
                      </section>
                      <section className="same-address-check">
                        <Controller
                          name="Checkbox"
                          control={control}
                          render={({ field }) => (
                            <Checkbox
                              onChange={(e) => {
                                // handleSameAddress(e.target.checked);
                                field.onChange(e.target.checked);
                              }}
                              checked={field.value}
                            />
                          )}
                        />
                        <label>
                          Kindly accept if you providing a reference letter from
                          previous landlord confirming good conduct and timeous
                          payments
                        </label>
                      </section>
                      <section className="same-address-check">
                        <Controller
                          name="Checkbox"
                          control={control}
                          render={({ field }) => (
                            <Checkbox
                              onChange={(e) => {
                                // handleSameAddress(e.target.checked);
                                field.onChange(e.target.checked);
                              }}
                              checked={field.value}
                            />
                          )}
                        />
                        <label>Are you currently employed</label>
                      </section>
                    </section>
                    <section className="section">
                      <label>
                        If a scholar please state your parents/guardian
                        occupation
                      </label>
                      <section>
                        <label htmlFor="motherName">Mother</label>
                        <input
                          type="text"
                          {...register("motherName", {
                            required: false,
                          })}
                        />
                        {/* {errors.bursarEmailAddress && (
                          <p>Your Bursar Email Address is required!</p>
                        )} */}
                      </section>
                      <section>
                        <label htmlFor="fatherName">Father</label>
                        <input
                          type="text"
                          {...register("fatherName", {
                            required: false,
                          })}
                        />
                        {/* {errors.bursarEmailAddress && (
                          <p>Your Bursar Email Address is required!</p>
                        )} */}
                      </section>
                      <section>
                        <label htmlFor="guardianName">
                          Guardian (if applicable)
                        </label>
                        <input
                          type="text"
                          {...register("guardianName", {
                            required: false,
                          })}
                        />
                        {/* {errors.bursarEmailAddress && (
                          <p>Your Bursar Email Address is required!</p>
                        )} */}
                      </section>
                    </section>
                  </Col>
                  <Col xs={12} md={3}>
                    <section className="section">
                      <label>
                        Name two companies where you have a credit arrangement
                        e.g. Woolworths. If you are a scholar please provide
                        parents account information
                      </label>
                      <section>
                        <label htmlFor="guardianName">
                          Guardian (if applicable)
                        </label>
                        <input
                          type="text"
                          {...register("guardianName", {
                            required: false,
                          })}
                        />
                        {/* {errors.bursarEmailAddress && (
                          <p>Your Bursar Email Address is required!</p>
                        )} */}
                      </section>
                      <section>
                        <label htmlFor="companyName">Name of company</label>
                        <input
                          type="text"
                          {...register("companyName", {
                            required: false,
                          })}
                        />
                        {/* {errors.bursarEmailAddress && (
                          <p>Your Bursar Email Address is required!</p>
                        )} */}
                      </section>
                      <section>
                        <label htmlFor="accountNumber">Account number</label>
                        <input
                          type="text"
                          {...register("accountNumber", {
                            required: false,
                          })}
                        />
                        {/* {errors.bursarEmailAddress && (
                          <p>Your Bursar Email Address is required!</p>
                        )} */}
                      </section>
                      <section>
                        <label htmlFor="companyName">Name of company</label>
                        <input
                          type="text"
                          {...register("companyName", {
                            required: false,
                          })}
                        />
                        {/* {errors.bursarEmailAddress && (
                          <p>Your Bursar Email Address is required!</p>
                        )} */}
                      </section>
                      <section>
                        <label htmlFor="accountNumber">Account number</label>
                        <input
                          type="number"
                          {...register("accountNumber", {
                            required: false,
                          })}
                        />
                        {/* {errors.bursarEmailAddress && (
                          <p>Your Bursar Email Address is required!</p>
                        )} */}
                      </section>
                    </section>
                  </Col>
                </Row>
              </Container>
            </Form.Row>
            <Row className="academic-btn-wrapper">
              <Col>
                {/* <input className="submit-btn" type="button" /> */}
                <Button className="submit-btn">Next</Button>
              </Col>
            </Row>
          </TabPanel>
        </div>
      </Form>
    </>
  );
}

export default CreditReference;
