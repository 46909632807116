import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Checkbox,
  Button,
} from "@material-ui/core";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import TabPanel from "../tab-panel";

import "react-datepicker/dist/react-datepicker.css";
import Navigation from "../navigation";

const defaultValues = {
  Native: "",
  TextField: "",
  Select: "",
  title: { value: "mr", label: "Mr" },
  Checkbox: false,
  switch: false,
  ReactDatepicker: new Date(),
  RadioGroup: "",
  numberFormat: 123456789,
  downShift: "apple",
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}));

function ApplicationSubmission() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({ defaultValues });

  const onSubmit = (data) => {
    console.log(JSON.stringify(data));
  };

  const classes = useStyles();
  const [value, setValue] = useState(0);

  return (
    <>
      <Navigation />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className={classes.root}>
          <AppBar position="static" color="default"></AppBar>
          <TabPanel value={value} index={0}>
            <Form.Row>
              <Container>
                <Row className="academic-details-wrapper">
                  <Col xs={12} md={5}>
                    <section className="section">
                      <section className="same-address-check">
                        <Controller
                          name="Checkbox"
                          control={control}
                          render={({ field }) => (
                            <Checkbox
                              onChange={(e) => {
                                // handleSameAddress(e.target.checked);
                                field.onChange(e.target.checked);
                              }}
                              checked={field.value}
                            />
                          )}
                        />
                        <label>
                          Che account holder as per credit information
                          furnished, hereby gives Kovacs Student Village
                          permission to do a credit worthiness evaluation
                        </label>
                      </section>
                      <section className="same-address-check">
                        <Controller
                          name="Checkbox"
                          control={control}
                          render={({ field }) => (
                            <Checkbox
                              onChange={(e) => {
                                // handleSameAddress(e.target.checked);
                                field.onChange(e.target.checked);
                              }}
                              checked={field.value}
                            />
                          )}
                        />
                        <label>
                          I confirm that all the information furnished is
                          complete and accurate
                        </label>
                      </section>
                      <section>
                        <label htmlFor="applicantName">Applicant Name</label>
                        <input
                          type="text"
                          {...register("applicantName", {
                            required: true,
                          })}
                        />
                        {errors.bursarEmailAddress && (
                          <p>Your Applicant name is required!</p>
                        )}
                      </section>
                    </section>
                  </Col>
                </Row>
              </Container>
            </Form.Row>
            <Row className="academic-btn-wrapper">
              <Col>
                {/* <input className="submit-btn" type="button" /> */}
                <Button className="submit-btn" type="submit">
                  Submit
                </Button>
              </Col>
            </Row>
          </TabPanel>
        </div>
      </Form>
    </>
  );
}

export default ApplicationSubmission;
