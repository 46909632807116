import React, { useEffect } from "react";
import { HashRouter as Switch, Route } from "react-router-dom";
import { StateMachineProvider, createStore } from "little-state-machine";
import logo from "./logo.svg";
import "./App.scss";
import ApplicationForm from "./components/application-form";
import MainPage from "./components/main-page";
import AcademicDetails from "./components/academic-details";
import FeesManagement from "./components/fees-magement";
import CreditReference from "./components/credit-reference";
import BursaryManagementFee from "./components/bursary-management-fee";
import ApplicationSubmission from "./components/application-submission";

createStore({});

function App() {
  return (
    <StateMachineProvider>
      <div className="App">
        <Switch>
          <Route exact path="/" component={MainPage} />
          <Route
            exact
            path="/personal-information"
            component={ApplicationForm}
          />
          <Route
            exact
            path="/academic-information"
            component={AcademicDetails}
          />
          <Route exact path="/fees-management" component={FeesManagement} />
          <Route exact path="/credit-reference" component={CreditReference} />
          <Route
            exact
            path="/bursary-management-fee"
            component={BursaryManagementFee}
          />
          <Route
            exact
            path="/application-submission"
            component={ApplicationSubmission}
          />
        </Switch>
      </div>
    </StateMachineProvider>
  );
}

export default App;
