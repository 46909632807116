import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Table } from "react-bootstrap";
import "./main-page.scss";
import Navigation from "../navigation";

function MainPage() {

  return (
    <>
      <Navigation />
      <div className="main-page">
        <h1>APPLICATION FORM</h1>

        <div className="form-details">
          <h4>
            PLEASE READ THE INSTRUCTIONS CAREFULLY IN ORDER TO COMPLETE THE
            APPLICATION FORM CORRECTLY
          </h4>
          <h4>PAYMENT PLAN</h4>
          <div>
            <strong>Non South African </strong> students are required to pay the
            full residence fee upfront.
          </div>
          <div>South African Students</div>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Payment Structure percentage paid at registration</th>
                <th>Discount</th>
                <th>Payment Term</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>100%</td>
                <td>50%</td>
                <td>15%</td>
              </tr>
              <tr>
                <td>2</td>
                <td>3.5%</td>
                <td>2.00%</td>
                <td>0</td>
              </tr>
              <tr>
                <td>3</td>
                <td>Before Intake</td>
                <td>
                  5 equal payments VIA DEBIT ORDER - Feb to Jun (Terms and
                  Conditions apply)
                </td>
                <td>
                  8 INSTALLMENTS IN TOTAL - 1 incl. with Deposit & equal
                  payments VIA DEBIT ORDER Feb to Aug (Terms and conditions
                  apply)
                </td>
              </tr>
              <tr>
                <td colSpan="5">
                  PLUS Refundable Breakage Deposit of R2900 on registration
                  (Terms and conditions apply)
                </td>
              </tr>
            </tbody>
          </Table>
          <Row>
            <Col className="form-instructions" xs={12} md={12}>
              <p>
                Should you require an alternative plan to the above, please
                consult with our accounts manager.
              </p>
              <div className="info-section">
                <h5>FULL BURSARY HOLDERS</h5>
                <p>
                  If you are a bursary student, we require a letter from the
                  bursar confirming the full Rand value, the disbursement date
                  if payment will be made directly to Kovacs or via the
                  university. Kindly provide us with a letter to this effect, a
                  standard template is also available on our website. For
                  bursary students whose payments are effected via the
                  university, approval will only be granted if there are no
                  outstanding fees due to UWC.
                </p>
              </div>
              <div className="info-section">
                <h5>PART BURSARY HOLDERS</h5>
                <p>
                  NFSAS bursary applicants should note that NSFAS approval is
                  only granted post intake and therefore cannot be used for
                  Kovacs approval. Should you receive NSFAS approval during the
                  year please consult with our office so that we can facilitate.
                  For all other bursaries where accommodation fees are only
                  partly covered the shortfall must be paid upfront. Should you
                  not have the full shortfall upfront please consult with our
                  accounts manager.
                </p>
              </div>
              <div className="info-section">
                <h5>
                  NON BURSARY HOLDERS (FOR SOUTH AFRICAN APPLICANTS ONLY):
                </h5>
                <p>
                  South African applicants choosing the 15% payment option must
                  provide credit reference of the payer. Credit reference to be
                  submitted include 1. Salary advice 2. Three months bank
                  statements (not older than 3 months) 3. Two statement of
                  accounts (e.g. Woolworths). Only after we approve your credit
                  worthiness may you pay the deposit. Final approval will be
                  granted on payment of the deposit and receipt of completed
                  debit order form. SA 50% applicants will receive approval on
                  payment of the 50% deposit, no credit reference documents
                  required. The balance to be collected via debit order.
                </p>
              </div>
              <div className="info-section">
                <h5>NON SOUTH AFRICAN APPLICANTS</h5>
                <p>
                  Non South African Students must ensure payments are made
                  timeously as international transfers takes 7 days to clear.
                  Full upfront payment is required. Non-South African students
                  are advised to apply for their visa well in advance to avoid
                  disappointment. Students without the necessary visa entry and
                  valid passports will not be accepted at the residences, even
                  if accepted into a UWC programme.
                </p>
              </div>
              <div className="info-section">
                <h5>DOCUMENTS REQUIRED</h5>
                <p>
                  Please note that the following documents will be required to
                  for your application to be fully processed.
                </p>
              </div>
              <div className="info-section">
                <h5>Bursary Students</h5>
                <p>
                  Confirmation of bursary letter Proof of zero or credit balance
                  in UWC student account
                </p>
              </div>
              <div className="info-section">
                <h5>SA 15%</h5>
                <ul>
                  <li>3 Months bank statements</li>
                  <li>Proof of income</li>
                  <li>2 Statements of accounts held, eg. Woolworths</li>
                </ul>
              </div>
              <div className="info-section">
                <h5>Non South African Students</h5>
                <ul>
                  <li>Visa</li>
                  <li>Medical Insurance</li>
                </ul>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default MainPage;
